import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ScrollUp } from '@use-gateway/components';
import { staticPropsFactory } from '@use-gateway/utils';
import {
  HeaderLanding,
  Jumbotron,
  Interfaces,
  ContactUs,
  LayoutLandingWrapper,
  Faq,
  FooterLanding,
  Solutions,
  CustomersSay,
  OurNews,
} from '../components';

export const getServerSideProps = staticPropsFactory({
  serverSideTranslations,
});

export default function Home() {
  return (
    <LayoutLandingWrapper>
      <HeaderLanding />
      <Jumbotron />
      <Interfaces />
      <Solutions />
      <CustomersSay />
      <Faq />
      <OurNews />
      <ContactUs />
      <FooterLanding />
      <ScrollUp />
    </LayoutLandingWrapper>
  );
}
